export const Work = () => {
	return (
		<div>
			<h1>Work</h1>
			<p>
				Coming soon. I'm getting it together to present nicely. In the meantime, why not check my:
        <div className="flex flex-col mt-5 gap-2">
          <a href={'https://github.com/smashind'} target={'_blank'} rel='noreferrer'>
						<i className="lab la-github"></i> GitHub
					</a>
					<a href={'https://www.linkedin.com/in/dannybaumann/'} target={'_blank'} rel='noreferrer'>
						<i className="lab la-linkedin-in"></i> LinkedIn
					</a>
        </div>

			</p>
		</div>
	)
}
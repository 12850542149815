export const About = () => {
	return (
		<div>
			<h1>About</h1>
			<p>
				Hello! 👋
			</p>
			<p>
				I'm Danny, a remote, front-end web developer based in California. I'm currently working with Hubstaff,
				where I help develop tools for effectively managing remote teams.
			</p>
			<p>
				I love working with CSS and JavaScript to transform static mockups into dynamic, user-friendly interfaces
				that will be used and relied on by people all over the world.
			</p>
			<p>
				Before going down the web development rabbit hole, I've worked in customer service, marketing, and SEO. That
				experience has really shaped the way I approach the development process and has given me a valuable perspective.
			</p>
		</div>
	)
}
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {Layout} from "./components/common"
import {Home, About, Work, NotFound} from "./components/pages"
import {SiteSettingsProvider, ThemeProvider} from "./contexts";

function App() {
  return (
      <ThemeProvider>
        <SiteSettingsProvider>
          <BrowserRouter basename={''}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="work" element={<Work />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </SiteSettingsProvider>
      </ThemeProvider>
  );
}

export default App;

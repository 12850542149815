import './home.css';
import Typed from "typed.js";
import { useEffect, useRef } from "react";

export const Home = () => {
	const el = useRef(null);

	useEffect(() => {
		const typed = new Typed(el.current, {
			strings: [
				"front-end developer",
				"React developer",
				"Vue developer",
				"email developer",
				"Ruby on Rails developer",
				"front-end developer",
			],

			startDelay: 300,
			typeSpeed: 85,
			backSpeed: 50,
			backDelay: 900
		});

		return () => {
			typed.destroy();
		};
	}, []);

	const skills = [
		{ icon: "lab la-react", name: "React" },
		{ icon: "lab la-vuejs", name: "Vue" },
		{ icon: "lab la-html5", name: "HTML" },
		{ icon: "lab la-css3", name: "CSS" },
		{ icon: "lab la-js-square", name: "JavaScript" },
		{ icon: "lar la-envelope-open", name: "Email development" },
		{ icon: "las la-gem", name: "Ruby on Rails" },
		{ icon: "lab la-wordpress", name: "WordPress" },
		{ icon: "las la-code-branch", name: "Git" },
		{ icon: "lab la-sass", name: "Sass" },
	];

	return (
		<>
			{/* <h1 className="sm:hidden">Remote front-end developer</h1> */}
			{/* <h1 className="hidden sm:block">An experienced, remote <br /><span ref={el}></span></h1> */}
			<h1>An enthusiastic, remote <br /><span ref={el}></span></h1>
			<p>
			  🏝️ Aloha! I'm Danny, a remote front-end developer with 10+ years experience in
				JavaScript, Vue, React, HTML, CSS, Email, Ruby on Rails, and WordPress. I'm reliable, focused, consistent,
				and easy to work with. And I have a sense of humor.
			</p>
			<div className="skills">
				{skills.map(skill => (
					<div key={skill.name} className="mastery">
						<i className={skill.icon}></i> {skill.name}
					</div>
				))}
			</div>
		</>

	)
}
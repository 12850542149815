import { Outlet } from 'react-router-dom';
import { Header, Footer } from "../template";

export const Layout = () => {
  return (
    <>
      <div className="dark:bg-gray-800 dark:text-white flex justify-center main-container px-2">
        <div className="flex flex-col justify-center max-w-2xl">
          <Header />
          <div className="flex flex-col justify-center content-container">
            <div className="flex flex-col justify-center">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
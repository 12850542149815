export const Footer = () => {
    return (
        <>
					<footer className="footer">
						<div className="contact-icons">
							<a href={'https://github.com/smashind'} target={'_blank'} rel='noreferrer'>
								<i className="lab la-github"></i>
							</a>
							<a href={'https://www.linkedin.com/in/dannybaumann/'} target={'_blank'} rel='noreferrer'>
								<i className="lab la-linkedin-in"></i>
							</a>
							<a href={'mailto:dannyjbaumann@gmail.com'} target={'_blank'} rel='noreferrer'>
								<i className="lar la-envelope"></i>
							</a>
						</div>
					</footer>
        </>
    )
}
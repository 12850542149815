
import {ThemeToggle} from "../common";
import { useNavigate } from 'react-router-dom';

export const Header = () => {
	const navigate = useNavigate();

	return (
		<>
			<header className="header flex justify-between items-center mt-5">
				<a onClick={() => navigate('/')}>Danny Baumann</a>
				<nav className="nav">
					<a onClick={() => navigate('/about')}>About</a>
					{/* <a onClick={() => navigate('/work')}>Work</a> */}
					<ThemeToggle />
				</nav>
			</header>
		</>
	)
}